import React from 'react';

const PrivacyModal = ({isOpen,onClose}) => {

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close-button" onClick={onClose}>&times;</span>
          <h2 style={{textAlign:"center"}}>Privacy Policy for [KidGlucoPal]</h2>
        </div>
        <div className="modal-body">
          {/* <span className='modal-subhead'>
            Here are some tips to help you maintain personal oral hygiene:
          </span> */}

          <div>
            <ol>
              <li>
                <span className='modal-subhead'></span>
                Introduction
                <ul>
                  <li className='modal-point'>Welcome to [KidGlucoPal]. This Privacy Policy is designed to help you understand how we collect, use, and safeguard your personal information. By using our website, you agree to the terms outlined in this policy.</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'></span>
                Information We Collect
                <ul>
                    <li>
                        <span className='modal-subhead'></span>
                        Personal Information:
                        <ul>
                        <li className='modal-point'>We may collect personal information such as names, email addresses, and other contact details when voluntarily provided by users.</li>
                        </ul>
                    </li>
                    <li>
                        <span className='modal-subhead'></span>
                        Non-Personal Information:
                        <ul>
                        <li className='modal-point'>We may also collect non-personal information, such as browser type, operating system, and IP address, to enhance the user experience.</li>
                        </ul>
                    </li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'></span>
                How We Use Your Information
                <ul>
                    <li>
                        <span className='modal-subhead'></span>
                        We use the collected information for the following purposes:
                        <ul>
                        <li className='modal-point'>To personalise user experience.</li>
                        <li className='modal-point'>To improve our website.</li>
                        <li className='modal-point'>To send periodic emails (only if the user has opted in).</li>
                        </ul>
                    </li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'></span>
                Cookies and Similar Technologies
                <ul>
                  <li className='modal-point'>We use cookies to enhance user experience. Users may choose to set their web browsers to refuse cookies or to alert them when cookies are being sent. Please note that certain features of the website may not function properly without cookies.</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'></span>
                Third-Party Disclosure
                <ul>
                  <li className='modal-point'>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as set forth in this Privacy Policy.</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'></span>
                Third-Party Links
                <ul>
                  <li className='modal-point'>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites. Please review the privacy policies of such sites for information on how they collect and use your personal information.</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'></span>
                Cookies and Similar Technologies
                <ul>
                  <li className='modal-point'>We use cookies to enhance user experience. Users may choose to set their web browsers to refuse cookies or to alert them when cookies are being sent. Please note that certain features of the website may not function properly without cookies.</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'></span>
                Security
                <ul>
                  <li className='modal-point'>We implement reasonable security measures to protect the confidentiality and security of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure.</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'></span>
                Changes to This Privacy Policy
                <ul>
                  <li className='modal-point'>We reserve the right to update or change our Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy on this page.</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'></span>
                Contact Us
                <ul>
                  <li className='modal-point'>If you have any questions about this Privacy Policy, please contact us at ahanas0206@gmail.com.</li>
                </ul>
              </li>

            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyModal;