import React, { useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer'; // Assuming you have a Footer component
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
  // const { pathname, hash } = useLocation();
  // useEffect(() => {
  //   const hash = window.location.hash;
  //   console.log(hash);
  //   if(hash == '#contact'){
  //     let timeout = setTimeout(() => {
  //       const el  = document.getElementById('contact');
  //       console.log(hash,el);
  //       el?.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'center',
  //         inline: 'nearest',
  //       });
  //     }, 200);
  //   }
    
  // },[hash]);
 return (
    <>
      <Header />
      {children}
      <Footer id="contact"/>
    </>
 );
};

export default Layout;