import React, { useState,useEffect } from "react";
import axios from "axios";
import PersonalModal from '../Components/PersonalModal';
import HealthModal from '../Components/HealthModal';

const Form = () => {
  const [step, setStep] = useState(1);
  const [formData1, setformData1] = useState({
    name: "",
    sex: "",
    age: "",
    heightFeet: "",
    heightInches: "",
    weight: "",
    address: "",
    mobileNo: "",
    email: "",
    previousDentalHistory: "",
    medication: "",
    allergy: "",
    presentIllnes: "",
    brush: "",
    floss: "",
    otherHygine: "",
    breakfast: "",
    brunch: "",
    lunch: "",
    snacks: "",
    dinner: "",
    anyotherfood: "",
    liquid: "",
    solid: "",
    slowlydissolve: "",
    result: "",
  });
  const [openPersonalModal,setPersonalModal] = useState(false);
  const [openHealthModal,setHealthModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

    const openModal = () => {
        setPersonalModal(true);
    }

    const closeModal = () => {
        setPersonalModal(false);
    }

    const handleOpenHealthModal = () => {
        setHealthModal(true);
    }

    const handleCloseHealthModal = () => {
        setHealthModal(false);
    }

  let tempResult;
  const handleChange = (event) => {
    setformData1({
      ...formData1,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission logic here
    if (step == 1) {
      console.log(formData1);
      setStep(2);
    } else if (step == 2) {
      console.log(formData1);
      setStep(3);
    } else if (step == 3) {
      console.log(formData1);
      tempResult =
        5 * formData1.liquid +
        10 * formData1.solid +
        15 * formData1.slowlydissolve;
      setformData1({
        ...formData1,
        [formData1.Result]: tempResult,
      });
      setStep(4);
      const submitAPIUrl = `${process.env.REACT_APP_BACKEND_URL}api/v1/testSubmit`;
      console.log(submitAPIUrl);

      const formData = {
        PersonalDetails: {
          Name: formData1.name,
          Sex: formData1.sex,
          Age: formData1.age,
          Height: {
            fts: formData1.heightFeet,
            inches: formData1.heightInches,
          },
          Weight: formData1.weight,
          Address: formData1.address,
          MobileNo: formData1.mobileNo,
          Email: formData1.email,
          PreviousDentalHistory: formData1.previousDentalHistory,
          Medication: formData1.medication,
          Allergies: formData1.allergy,
          PresentIllness: formData1.presentIllnes,
          BrushTeeth: formData1.brush,
          Floos: formData1.floss,
          OtherDentalHygineMethod: formData1.otherHygine,
        },
        Diet: {
          Breakfast: formData1.breakfast,
          Brunch: formData1.brunch,
          Lunch: formData1.lunch,
          Snack: formData1.snacks,
          Dinner: formData1.dinner,
          OtherFood: formData1.anyotherfood,
        },
        SweetStat: {
          Liquid: formData1.liquid,
          Solid: formData1.solid,
          SlowDissolveFood: formData1.slowlydissolve,
        },
        Result: tempResult,
      };

      try {
        console.log("response", formData);
        const response = await axios.post(
          submitAPIUrl,
          JSON.stringify(formData),
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        console.log(response);
      } catch (error) {
        console.log(error);
        throw new Error("Error Submitting - ", error.message);
      }
    }
  };

  return (
    <div className="form">
      <div className="form-img">
        <img src={`/form${step}.png`} alt="form" />
      </div>
      <div className="form-div">
        <div className="form-div-inner">
          <div className="form-div-stepslabel">
            {step == 1 && <h2>Personal Details</h2>}
            {step == 2 && <h2>Diet</h2>}
            {step == 3 && <h2>Sweetstats</h2>}
            {step == 4 && <h2 style={{textAlign:"center"}}>Result</h2>}
          </div>

          <div>
            {step == 1 && (
              <form onSubmit={handleSubmit}>
                {/* name */}
                <div className="form-div-label-1">
                  <label>Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData1.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* sex */}
                <div>
                  <div>
                    <label>Sex</label>
                  </div>
                  <div className="form-div-label-2">
                    <div className="form-div-label-3">
                      <input
                        type="radio"
                        id="male"
                        name="sex"
                        value="male"
                        checked={formData1.sex.toLowerCase() === "male"}
                        onChange={handleChange}
                      />
                      <label className="form-div-label-4" htmlFor="male">
                        Male
                      </label>
                    </div>
                    <div className="form-div-label-3">
                      <input
                        type="radio"
                        id="female"
                        name="sex"
                        value="female"
                        checked={formData1.sex.toLowerCase() === "female"}
                        onChange={handleChange}
                      />
                      <label className="form-div-label-4" htmlFor="female">
                        Female
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="other"
                        name="sex"
                        value="other"
                        checked={formData1.sex.toLowerCase() === "other"}
                        onChange={handleChange}
                      />
                      <label className="form-div-label-4" htmlFor="other">
                        Other
                      </label>
                    </div>
                  </div>
                </div>
                {/* Age */}
                <div className="form-div-label-1">
                  <label htmlFor="age">Age</label>
                  <input
                    type="number"
                    id="age"
                    name="age"
                    value={formData1.age}
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* Height */}
                <div>
                  <div>
                    <label>Height</label>
                  </div>
                  <div>
                    <input
                      type="number"
                      id="ft"
                      name="heightFeet"
                      value={formData1.heightFeet}
                      onChange={handleChange}
                      required
                    />
                    <span>ft</span>
                  </div>
                  <div>
                    <input
                      type="number"
                      id="inch"
                      name="heightInches"
                      value={formData1.heightInches}
                      onChange={handleChange}
                      required
                    />
                    <span>inch</span>
                  </div>
                </div>
                {/* Weight */}
                <div>
                  <div>
                    <label htmlFor="weight">Weight</label>
                  </div>

                  <div>
                    <input
                      type="number"
                      id="weight"
                      name="weight"
                      value={formData1.weight}
                      onChange={handleChange}
                      required
                    />
                    <span>kg</span>
                  </div>
                </div>

                {/* address */}
                <div className="form-div-label-1">
                  <label>Address</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={formData1.address}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Mobile */}
                <div className="form-div-label-1">
                  <label>Mobile No.</label>
                  <input
                    type="text"
                    id="mobileNo"
                    name="mobileNo"
                    value={formData1.mobileNo}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* email */}
                <div className="form-div-label-1">
                  <label>Email</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={formData1.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Previous Dental History (if any) */}
                <div className="form-div-label-1">
                  <label>Previous Dental History (if any)</label>
                  <input
                    type="text"
                    id="previousDentalHistory"
                    name="previousDentalHistory"
                    value={formData1.previousDentalHistory}
                    onChange={handleChange}
                    // required
                  />
                </div>

                {/* Previous Dental History (if any) */}
                <div className="form-div-label-1">
                  <label>Medication (if any)</label>
                  <input
                    type="text"
                    id="medication"
                    name="medication"
                    value={formData1.medication}
                    onChange={handleChange}
                    // required
                  />
                </div>

                {/* Previous Dental History (if any) */}
                <div className="form-div-label-1">
                  <label>Allergies (if any)</label>
                  <input
                    type="text"
                    id="allergy"
                    name="allergy"
                    value={formData1.allergy}
                    onChange={handleChange}
                    // required
                  />
                </div>

                {/* Previous Dental History (if any) */}
                <div className="form-div-label-1">
                  <label>Any Present Illness</label>
                  <input
                    type="text"
                    id="presentIllnes"
                    name="presentIllnes"
                    value={formData1.presentIllnes}
                    onChange={handleChange}
                    // required
                  />
                </div>

                {/* Brush */}
                <div>
                  <div>
                    <label>Brushing Teeth</label>
                  </div>
                  <div className="form-div-label-2">
                    <div className="form-div-label-3">
                      <input
                        type="radio"
                        id="once"
                        name="brush"
                        value="once"
                        checked={formData1.brush.toLowerCase() === "once"}
                        onChange={handleChange}
                      />
                      <label className="form-div-label-4" htmlFor="once">
                        Once
                      </label>
                    </div>
                    <div className="form-div-label-3">
                      <input
                        type="radio"
                        id="twice"
                        name="brush"
                        value="twice"
                        checked={formData1.brush.toLowerCase() === "twice"}
                        onChange={handleChange}
                      />
                      <label className="form-div-label-4" htmlFor="twice">
                        Twice
                      </label>
                    </div>
                  </div>
                </div>

                {/* Floss */}
                <div>
                  <div>
                    <label>Floss</label>
                  </div>
                  <div className="form-div-label-2">
                    <div className="form-div-label-3">
                      <input
                        type="radio"
                        id="yes"
                        name="floss"
                        value="yes"
                        checked={formData1.floss.toLowerCase() === "yes"}
                        onChange={handleChange}
                      />
                      <label className="form-div-label-4" htmlFor="yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-div-label-3">
                      <input
                        type="radio"
                        id="no"
                        name="floss"
                        value="no"
                        checked={formData1.floss.toLowerCase() === "no"}
                        onChange={handleChange}
                      />
                      <label className="form-div-label-4" htmlFor="no">
                        No
                      </label>
                    </div>
                  </div>
                </div>

                {/* Floss */}
                <div>
                  <div>
                    <label>Any other dental hygiene methods used</label>
                  </div>
                  <div className="form-div-label-2">
                    <div className="form-div-label-3">
                      <input
                        type="radio"
                        id="floss"
                        name="otherHygine"
                        value="floss"
                        checked={formData1.otherHygine.toLowerCase() == "floss"}
                        onChange={handleChange}
                      />
                      <label className="form-div-label-4" htmlFor="floss">
                        Floss
                      </label>
                    </div>
                    <div className="form-div-label-3">
                      <input
                        type="radio"
                        id="mouthwash"
                        name="otherHygine"
                        value="mouthwash"
                        checked={
                          formData1.otherHygine.toLowerCase() == "mouthwash"
                        }
                        onChange={handleChange}
                      />
                      <label className="form-div-label-4" htmlFor="mouthwash">
                        Mouthwash
                      </label>
                    </div>
                    <div className="form-div-label-3">
                      <input
                        type="radio"
                        id="gargle"
                        name="otherHygine"
                        value="gargle"
                        checked={
                          formData1.otherHygine.toLowerCase() == "gargle"
                        }
                        onChange={handleChange}
                      />
                      <label className="form-div-label-4" htmlFor="gargle">
                        Gargle After Every Meal
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-btn">
                  <button type="submit">NEXT</button>
                </div>
              </form>
            )}
            {step == 2 && (
              <form onSubmit={handleSubmit}>
                <div className="form-div-label-1">
                  <label>Breakfast</label>
                  <input
                    type="text"
                    id="breakfast"
                    name="breakfast"
                    value={formData1.breakfast}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-div-label-1">
                  <label>Brunch</label>
                  <input
                    type="text"
                    id="brunch"
                    name="brunch"
                    value={formData1.brunch}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-div-label-1">
                  <label>Lunch</label>
                  <input
                    type="text"
                    id="lunch"
                    name="lunch"
                    value={formData1.lunch}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-div-label-1">
                  <label>Snacks</label>
                  <input
                    type="text"
                    id="snacks"
                    name="snacks"
                    value={formData1.snacks}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-div-label-1">
                  <label>Dinner</label>
                  <input
                    type="text"
                    id="dinner"
                    name="dinner"
                    value={formData1.dinner}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-div-label-1">
                  <label>Any Other Food Item</label>
                  <input
                    type="text"
                    id="anyotherfood"
                    name="anyotherfood"
                    value={formData1.anyotherfood}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-btn">
                  <button type="submit">NEXT</button>
                </div>
              </form>
            )}
            {step == 3 && (
              <form onSubmit={handleSubmit}>
                <div style={{ display: "flex" }} className="form-card-parent">
                  <div className="card">
                    <div className="card-head">
                      <h2>Liquid</h2>
                    </div>
                    <div className="card-text">
                      <span>
                        Soft drinks, fruit drinks, cocoa, sugar and honey in
                        beverages, ice cream, flavoured yogurt, pudding,
                        custard, sweetened ice tea, sweetened water, sodas,
                        flavoured milk, coffee and tea with syrup.
                      </span>
                    </div>
                    <div className="form-div-label-1 card-label">
                      <input
                        type="text"
                        id="liquid"
                        name="liquid"
                        value={formData1.liquid}
                        onChange={handleChange}
                        required
                        // placeholder="Total no."
                        placeholder="Give one score to each "
                      />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-head">
                      <h2>Solid</h2>
                    </div>
                    <div className="card-text">
                      <span>
                        Cake, doughnuts, sweet rolls, pastry, canned fruit in
                        syrup, bananas, cookies, chocolate candy, caramel,
                        chewing gum, dried fruit, marshmallows, jelly, jam,
                        fudge , caramel.
                      </span>
                    </div>
                    <div className="form-div-label-1 card-label">
                      <input
                        type="text"
                        id="solid"
                        name="solid"
                        value={formData1.solid}
                        onChange={handleChange}
                        required
                        // placeholder="Total no."
                        placeholder="Give one score to each "
                      />
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-head">
                      <h2>Slowly Dissolving / Slowly Breaking Down Food</h2>
                    </div>
                    <div className="card-text">
                      <span>
                        Hard candies, breathe mints, antacid tablets, cough
                        drops, Licorice, Gumdrops, Jolly ranchers.
                      </span>
                    </div>
                    <div className="form-div-label-1 card-label">
                      <input
                        type="text"
                        id="slowlydissolve"
                        name="slowlydissolve"
                        value={formData1.slowlydissolve}
                        onChange={handleChange}
                        required
                        // placeholder="Total no."
                        placeholder="Give one score to each "
                      />
                    </div>
                  </div>
                </div>

                <div className="form-btn">
                  <button type="submit">NEXT</button>
                </div>
              </form>
            )}

            {step == 4 && (
                <>
                <div style={{display:"flex",justifyContent:"center"}}>
                  <h2 style={{color:(5 * formData1.liquid + 10 * formData1.solid + 15 * formData1.slowlydissolve)<=5 ? '#1EB02C' : (5 * formData1.liquid + 10 * formData1.solid + 15 * formData1.slowlydissolve) >= 15 ? '#E71A1A' : '#F1B415', textAlign:"center",fontSize:"2.5rem"}}>
                    {5 * formData1.liquid +
                      10 * formData1.solid +
                      15 * formData1.slowlydissolve}
                  </h2>
                  <img
                    src={(5 * formData1.liquid + 10 * formData1.solid + 15 * formData1.slowlydissolve)<=5 ? 'res1.svg' :(5 * formData1.liquid + 10 * formData1.solid + 15 * formData1.slowlydissolve) >= 15 ? 'res3.svg': 'res2.svg'}
                    style={{width:'4rem',marginLeft:'1rem'}}
                  />
                </div>
                <div>
                  {(5 * formData1.liquid + 10 * formData1.solid + 15 * formData1.slowlydissolve) <= 5 && <h2 style={{textAlign:"center",fontWeight:"600",marginBottom:"1rem"}}>Good job! Your score is excellent.</h2>}
                  {(5 * formData1.liquid + 10 * formData1.solid + 15 * formData1.slowlydissolve) < 15 && (5 * formData1.liquid + 10 * formData1.solid + 15 * formData1.slowlydissolve) > 5 && <h2 style={{textAlign:"center",fontWeight:"600",marginBottom:"1rem"}}>Your are good but barely safe.</h2>}
                  {(5 * formData1.liquid + 10 * formData1.solid + 15 * formData1.slowlydissolve) >= 15 && <h2 style={{color:"#E71A1A",textAlign:"center",fontWeight:"600",marginBottom:"1rem"}}>Your score seems pretty high, consult a doctor.</h2>}
                </div>
                <div style={{display:"flex",justifyContent:"center"}}>
                  <img
                      src={isMobile? "/status_mb.png" : "/status.png"}
                      alt="status"
                      style={{width:"65%"}}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {step == 4 && (
        // <div className="result-img">
        //   <button className="service-list-first" onClick={openModal}>
        //     <img src="/personal.png" />
        //   </button>
        //   <button
        //     className="service-list-second"
        //     onClick={handleOpenHealthModal}
        //   >
        //     <img src="/health.png" />
        //   </button>
        // </div>
        // <div className='service-list' style={{justifyContent:"center"}}>
        //     <button className='service-list-first' onClick={openModal}>
        //         {/* <img
        //             src='/personal.png'
        //         /> */}
        //         <div>
        //             <img
        //                 src='/personal1.png'
        //                 className='service-list-img'
        //             />
        //             <h3 className='service-list-text'>Personal Oral <br/>Hygiene</h3>
        //         </div>
        //     </button>
        //     <button className='service-list-second' onClick={handleOpenHealthModal}>
        //         {/* <img
        //             src='/health.png'
        //         /> */}
        //         <div>
        //             <img
        //                 src='/health1.png'
        //                 className='service-list-img'
        //             />
        //             <h3 className='service-list-text'>Healthy Dietary  <br/>Substitute</h3>
        //         </div>
        //     </button>
        // </div>
        <div className='service-list' style={{justifyContent:"center"}}>
            <div className='service-div1' onClick={openModal}>
                <img
                    src='/service1.png'
                    // className='service-list-img-btn-img1'
                />
            </div>
           
            <div className='service-div2' onClick={handleOpenHealthModal}>
                <img
                    src='/service2.png'
                    // style={{paddingTop:"1.2rem"}}
                    // className='service-list-img-btn-img2'
                />
            </div>
             
        </div>
      )}
      {
            openPersonalModal && <PersonalModal isOpen={openPersonalModal} onClose={closeModal}/>
        }
        {
            openHealthModal && <HealthModal isOpen={openHealthModal} onClose={handleCloseHealthModal}/>
        }
    </div>
  );
};

export default Form;
