import React, { useState,useEffect } from "react";
import Query from "./Query";
import PrivacyModal from "./PrivacyModal";
import { Link } from "react-router-dom";

const Footer = () => {
    const[openModal,setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }
    // useEffect(() => {
    //     const hash = window.location.hash;
    //     console.log(hash);
    //     if(hash == '#contact'){
    //       const el  = document.getElementById('contact');
    //       console.log(hash,el);
    //       el?.scrollIntoView({
    //         behavior: 'smooth',
    //         block: 'center',
    //         inline: 'nearest',
    //       });
    //     }
        
    //   },[window.location.hash]);
    return(
        <>
        {openModal && <PrivacyModal isOpen={openModal} onClose={handleCloseModal}/>}
        <div className="footer" id='contact'>
            <div className="query">
                <Query/>
            </div>
            <div className="footer-text">
                <h2>
                    LET'S CHAT
                </h2>
                <h4>Question, comments or requests? Feel free to reach out we’d love to hear from you.</h4>
                <h4>New Delhi - 110060</h4>
                <h4>ahanas0206@gmail.com</h4>
                <h4>+91 8802004154</h4>
                <Link onClick={handleOpenModal} style={{textDecoration:'none',color:'white'}}><h5 >T&C | Privacy Policy</h5></Link>
            </div>
        </div>
        </>
    );
}

export default Footer