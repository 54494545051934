import React, { useEffect } from "react";
import Test from "../Components/Test";
import Experience from "../Components/Experience";
import Jouney from "../Components/Journey";
import Vision from "../Components/Vision";
import Service from "../Components/Service";
import FAQ from "../Components/FAQ";

const Home = () => {

    useEffect(() => {
        window.scrollTo(0,  0);
    });

    return(
        <>
            <Test/>
            <Experience/>
            <Jouney/>
            <Vision id="about"/>
            <Service/>
            <FAQ/>
        </>
    );
}

export default Home;