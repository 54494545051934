import React,{useState,useEffect} from "react";
import { useNavigate } from 'react-router-dom';


const Test = () => {
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    useEffect(() => {
       const handleResize = () => {
         setIsMobile(window.innerWidth <= 767);
       };
   
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleButtonClick = () => {
        navigate('/form');
    };
    return(
       <div className="brush" style={{position:"relative"}}>
            {isMobile ?
                <div>
                    <img
                        src="/test_mb.png"
                        alt="test"
                    />
                </div>

             :
            //  <div>
            //     <div className="brush-img1">
            //         <img
            //             src="/test.png"
            //             alt="test"
            //         />
            //     </div>
            //     <div className="brush-btn">
            //         <button >
            //             <img
            //                 src='/testbtn.png'
            //             />
            //         </button>
            //     </div>
            // </div>
            <div>
                    <img
                        src="/testcomplete.png"
                        alt="test"
                    />
                </div>
            }
            
       </div>

    );
}

export default Test;