import React from 'react';
import PhotoSlider from './PhotoSlider';

const Experience = () => {
  const photos = [
    { url: '/img1.png', title: 'Image  1' },
    { url: '/img2.png', title: 'Image  2' },
    { url: '/img3.png', title: 'Image  3' },
    { url: '/img4.png', title: 'Image  4' },
    { url: '/img5.png', title: 'Image  5' },
    { url: '/img7.png', title: 'Image  7' },
    { url: '/img8.png', title: 'Image  8' },
    { url: '/img9.png', title: 'Image  9' },
    { url: '/img10.png', title: 'Image 10' },
    { url: '/img11.png', title: 'Image 11' }
  ];

  return (
    <div className='photoslider'>
        <div className='photoslider-text'>
            <h2>
                Dive into an experience that sets us <br/>
                apart in an ever-evolving landscape.
            </h2>
            <h4>
            At KIDGLUCOPAL, we don’t just offer solutions; We redefine <br/> possibilities.
            </h4>
        </div>
        <div className='photoslider-div'>
            <PhotoSlider photos={photos} />
        </div>
    </div>
  );
};

export default Experience;