import React, { useEffect, useState } from 'react';
import PersonalModal from './PersonalModal';
import HealthModal from './HealthModal';

const Service = () => {
    const [openPersonalModal,setPersonalModal] = useState(false);
    const [openHealthModal,setHealthModal] = useState(false);

    const openModal = () => {
        setPersonalModal(true);
    }

    const closeModal = () => {
        setPersonalModal(false);
    }

    const handleOpenHealthModal = () => {
        setHealthModal(true);
    }

    const handleCloseHealthModal = () => {
        setHealthModal(false);
    }


  return (
    <div className='service'>
        {
            openPersonalModal && <PersonalModal isOpen={openPersonalModal} onClose={closeModal}/>
        }
        {
            openHealthModal && <HealthModal isOpen={openHealthModal} onClose={handleCloseHealthModal}/>
        }
        <h2>
            our service
        </h2>
        {/* <div className='service-list'>
            <button className='service-list-first' onClick={openModal}>
                <div>
                    <img
                        src='/personal1.png'
                        className='service-list-img'
                    />
                    <h3 className='service-list-text'>Personal Oral <br/>Hygiene</h3>
                </div>
            </button>
            <button className='service-list-second' onClick={handleOpenHealthModal}>
                <div>
                    <img
                        src='/health1.png'
                        className='service-list-img'
                    />
                    <h3 className='service-list-text'>Healthy Dietary  <br/>Substitute</h3>
                </div>
            </button>
        </div> */}
        <div className='service-list'>
            <div className='service-div1' onClick={openModal}>
                <img
                    src='/service1.png'
                    // className='service-list-img-btn-img1'
                />
            </div>
           
            <div className='service-div2' onClick={handleOpenHealthModal}>
                <img
                    src='/service2.png'
                    // style={{paddingTop:"1.2rem"}}
                    // className='service-list-img-btn-img2'
                />
            </div>
             
        </div>
    </div>
  );
};

export default Service;