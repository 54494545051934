import React,{useState} from "react";
import axios from "axios";

const Query = () => {
    const [formState, setFormState] = useState({
        Name: '',
        Email: '',
        Message: ''
     });
    
     const handleChange = (event) => {
        setFormState({
            ...formState,
            [event.target.name]: event.target.value
        });
    };
    
     const handleSubmit = async (event) => {
        event.preventDefault();
        // Handle form submission logic here
        try{
            console.log('response',formState);
            const submitAPIUrl = `${process.env.REACT_APP_BACKEND_URL}api/v1/querySubmit`;
            
            const response = await axios.post(submitAPIUrl, formState, {
                headers: {
                  'Content-Type': 'application/json',
                  'x-api-key': process.env.REACT_APP_API_KEY
                },
            });
            if(response.status == 201){
                setFormState({
                    Name: '',
                    Email: '',
                    Message: ''
                });
            }
            console.log(response);

        }catch(error){
            console.log(error);
            throw new Error('Error Submitting - ',error.message);
        }
        console.log(formState);
     };
    
    return(
        <div className="query-form">
            <form onSubmit={handleSubmit}>
                <div style={{display:"flex"}}>
                    <input
                        type="text"
                        id="name"
                        name="Name"
                        value={formState.Name}
                        onChange={handleChange}
                        placeholder="Name (required)"
                        required
                        style={{marginRight:"0.5rem"}}
                    />

                    <input
                        type="email"
                        id="email"
                        name="Email"
                        value={formState.Email}
                        onChange={handleChange}
                        placeholder="Email (required)"
                        required
                        style={{marginLeft:"0.5rem"}}
                    />
                </div>
                <div style={{paddingTop:"1rem"}}>
                    <textarea
                        id="message"
                        name="Message"
                        value={formState.Message}
                        onChange={handleChange}
                        placeholder="Message"
                        required
                    />
                </div>
                <div style={{padding:"1rem 0"}}>
                    <button type="submit">SEND</button>
                </div>
            </form>
        </div>
    );
}

export default Query;