import React from 'react';

const HealthModal = ({isOpen,onClose}) => {

  return (
    <div className="modal">
      <div className="modal-content">
      <span className="close-button" onClick={onClose}>&times;</span>
        <div className="modal-header">
          <div className='blue-box'>
            <h2 >
                HEALTHFUL SWEETENERS
            </h2>
            <span>Remember, it's not about completely eliminating sugar but rather about making mindful choices and reducing unnecessary added sugars in your diet.</span><br/>
            <span>Gradual changes are often more sustainable, so take small steps towards a less sugar-centric diet.</span>
          </div>
        </div>
        <div className="modal-body">
          <table>
            <thead>
                <tr>
                    <th className='left-head'>FOOD ITEMS</th>
                    <th className='right-head'>HEALTHY SUBSTITUTES</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='left'>Chips</td>
                    <td className='right'>Vegetable chips/baked potato chips/cucumber chips/rice cakes</td>
                </tr>

                <tr>
                    <td className='left'>Chocolates</td>
                    <td className='right'>Dark chocolate/dried fruit/ yoghurt with cocoa/chia seed pudding/ fruit with nut butter/fruits dipped in almond or peanut butter</td>
                </tr>

                <tr>
                    <td className='left'>Maggi/pasta</td>
                    <td className='right'>Whole grain pasta/noodles, soup with whole ingredients, brown rice noodles</td>
                </tr>

                <tr>
                    <td className='left'>Cold Drinks</td>
                    <td className='right'>Coconut water/Herbal tea/iced green tea/vegetable or homemade fruit juice/Nimbu paani </td>
                </tr>

                <tr>
                    <td className='left'>Cake</td>
                    <td className='right'>Chia seed pudding/coconut flour cake/ jaggery cake/oatmeal cookies/ baked apple or pear</td>
                </tr>

                <tr>
                    <td className='left'>Lollipop/candy</td>
                    <td className='right'>Fruit pops/freeze dried fruits/coconut pops/dark-chocolate dipped fruit/honey sticks/home-made gummy treats/ chilled grapes</td>
                </tr>

                <tr>
                    <td className='left'>Fried food</td>
                    <td className='right'>Baked food/grilled food/ roasted food/ boiled food/slow cooking</td>
                </tr>

                <tr>
                    <td className='left'>Flavoured milk</td>
                    <td className='right'>Unsweetened almond milk/soy milk/cashew milk/oat milk/coconut milk, herbal teas, flavoured water, homemade infused milk</td>
                </tr>

                <tr>
                    <td className='left'>Ice-cream</td>
                    <td className='right'>Frozen Yoghurt/Sugar-free ice creams</td>
                </tr>

                <tr>
                    <td className='left'>Breath mints/ jolly ranchers</td>
                    <td className='right'>Sugar-free mints/gums, mint leaves/cinnamon sticks</td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HealthModal;