import React, {useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

const Jouney = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    useEffect(() => {
       const handleResize = () => {
         setIsMobile(window.innerWidth <= 767);
       };
   
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleButtonClick = () => {
        navigate('/form');
        window.scrollTo(0,  0);
    };

  return (
    <div style={{position:"relative", paddingTop:"3rem"}}>
        <div className='child-parent'>
            <img
                src={isMobile? '/child_mb.png' :'/child.png'}
                alt='child'
            />
        </div>
        <div className='child-child'>
            {
                isMobile? null :
                <div>
                    <h1>
                        Discover a world <br/> of healthy smiles
                    </h1>
                </div>
            }
            
            <div>
                <button onClick={handleButtonClick}>Start your journey</button>
            </div>
        </div>
    </div>
  );
};

export default Jouney;