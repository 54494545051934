import React, { useEffect, useState } from "react";
import axios from "axios";

const Admin = () => {
    const [form,setForm] = useState({
        Email: '',
        Password: ''
    });
    const [login,setLogin] = useState(null);
    const [data,setData] = useState(null);
    const [type,setType] = useState(null);

    const handleData = async (type) => {
        let submitAPIUrl;
        if(type == 'test'){
            submitAPIUrl = `${process.env.REACT_APP_BACKEND_URL}api/v1/getTest`;
            setType('test');
        }else if(type== 'query'){
            submitAPIUrl = `${process.env.REACT_APP_BACKEND_URL}api/v1/getQuery`;
            setType('query');
        }
        try{
            const response = await axios.get(submitAPIUrl, {
                headers: {
                  'Content-Type': 'application/json',
                  'x-api-key': process.env.REACT_APP_API_KEY
                },
            });
            if(response.status == 200){
                setData(response.data);
            }
        }catch(error){
            console.log(error);
            throw new Error('Error Submitting - ',error.message);
        }
    }

    useEffect(() => {console.log('login',login)},[login])

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Handle form submission logic here
        try{
            console.log('response',form);
            const submitAPIUrl = `${process.env.REACT_APP_BACKEND_URL}api/v1/login`;
           
            const response = await axios.post(submitAPIUrl, form, {
                headers: {
                  'Content-Type': 'application/json',
                  'x-api-key': process.env.REACT_APP_API_KEY
                },
            });
            if(response.status == 200){
                setForm({
                    Email: '',
                    Password: ''
                });
                setLogin(true);
            }
            else{
                setLogin(false);
            }
            
            console.log(response);

        }catch(error){
            setForm({
                Email: '',
                Password: ''
            });
            console.log(error);
            throw new Error('Error Submitting - ',error.message);
        }
        console.log(form);
     };

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
    };

    return(
        <>
            {(login == null || login == false) && 
                <div>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="email">Email:</label>
                        <input
                        type="email"
                        id="email"
                        name="Email"
                        value={form.Email}
                        onChange={handleChange}
                        required
                        />

                        <label htmlFor="password">Password:</label>
                        <input
                        type="password"
                        id="password"
                        name="Password"
                        value={form.Password}
                        onChange={handleChange}
                        required
                        />

                        <button type="submit">Submit</button>
                    </form>
                </div>
            } 
            {
                login == true && data == null && 
                <div>
                    <button onClick={() => handleData('test')}>Test Data</button>
                    <button onClick={() => handleData('query')}>Query</button>
                </div>
            }
            {
                data != null &&
                <div>
                {/* <div>
                    <button onClick={() => handleData('test')}>Test Data</button>
                    <button onClick={() => handleData('query')}>Query</button>
                </div> */}
                <div>
                    {data.map((item, index) => (
                         <div key={item._id} style={{marginTop:"1rem", background:"grey", border:"2px solid black"}}>
                            {type == 'test' && 
                                <>
                                <span>Name : {item.PersonalDetails.Name}</span> <br/>
                                <span>Sex : {item.PersonalDetails.Sex}</span><br/>
                                <span>Age : {item.PersonalDetails.Age}</span><br/>
                                <span>Height : {item.PersonalDetails.Height.fts} fts {item.PersonalDetails.Height.inches} inches</span><br/>
                                <span>Weight : {item.PersonalDetails.Weight}</span><br/>
                                <span>Address : {item.PersonalDetails.Address}</span><br/>
                                <span>MobileNo : {item.PersonalDetails.MobileNo}</span><br/>
                                <span>Email : {item.PersonalDetails.Email}</span><br/>
                                <span>PreviousDentalHistory : {item.PersonalDetails.PreviousDentalHistory}</span><br/>
                                <span>Medication : {item.PersonalDetails.Medication}</span><br/>
                                <span>Allergies : {item.PersonalDetails.Allergies}</span><br/>
                                <span>PresentIllness : {item.PersonalDetails.PresentIllness}</span><br/>
                                <span>BrushTeeth : {item.PersonalDetails.BrushTeeth}</span><br/>
                                <span>Floos : {item.PersonalDetails.Floos}</span><br/>
                                <span>OtherDentalHygineMethod : {item.PersonalDetails.OtherDentalHygineMethod}</span><br/>
                                <span>Breakfast : {item.Diet.Breakfast}</span><br/>
                                <span>Brunch : {item.Diet.Brunch}</span><br/>
                                <span>Lunch : {item.Diet.Lunch}</span><br/>
                                <span>Snack : {item.Diet.Snack}</span><br/>
                                <span>Dinner : {item.Diet.Dinner}</span><br/>
                                <span>OtherFood : {item.Diet.OtherFood}</span><br/>
                                <span>Liquid : {item.SweetStat.Liquid}</span><br/>
                                <span>Solid : {item.SweetStat.Solid}</span><br/>
                                <span>SlowDissolveFood : {item.SweetStat.SlowDissolveFood}</span><br/>
                                </>
                            }
                            {type == 'query' &&
                                <>
                                    <span>Name : {item.Name}</span> <br/>
                                    <span>Email : {item.Email}</span> <br/>
                                    <span>Message : {item.Message}</span> <br/>
                                </>
                            }


                            
                         </div>
                    ))}
                </div>
                </div>
            }
            
        </>
    );
}

export default Admin;