import React, { useState, useEffect, useRef } from 'react';

const Vision = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    useEffect(() => {
       const handleResize = () => {
         setIsMobile(window.innerWidth <= 767);
       };
   
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
    <div id='about'>
        {/* <div className='vision'>
            <h2>our vision</h2>

            <div className='row' style={{display:"flex"}}>
                <div className='col-8 col-xl-7  vision-left'>
                    <div>
                        <span>
                        Our vision is to lead in advancing pediatric dentistry, ensuring the highest care standards, minimizing negative experiences, and integrating technology, personalized care, and a holistic approach for the field's forefront development. The aim is to shift the focus from reactive treatment of dental problems to proactive measures that preserve and promote oral health.
                        </span>
                    </div>
                    <div>
                        <img src='/icon1.png' alt='icon'/>
                        <img src='/icon2.png' alt='icon'/>
                        <img src='/icon3.png' alt='icon'/>
                        <img src='/icon4.png' alt='icon'/>
                    </div>
                </div>
                <div className='vision-right col-xl-5'>
                    <img
                        src='/doctor.png'
                        alt='doctor'
                    />
                </div>
            </div>
        </div> */}
        {isMobile ? (
            <div className='vision-img'>
            <img src='/vision_mb.png' alt='icon' />
            </div>
        ) : (
            <div className='vision-img'>
            <img src='/vision.png' alt='icon' />
            </div>
        )}
    </div>
    );
};

export default Vision;