import React from 'react';

const PersonalModal = ({isOpen,onClose}) => {

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="close-button" onClick={onClose}>&times;</span>
          <h2>Maintaining good oral hygiene is essential for overall health and well-being.</h2>
        </div>
        <div className="modal-body">
          <span className='modal-subhead'>
            Here are some tips to help you maintain personal oral hygiene:
          </span>

          <div>
            <ol>
              <li>
                <span className='modal-subhead'>Brush Your Teeth:</span>
                
                <ul>
                  <li className='modal-point'>Frequency: Brush your teeth at least twice a day.</li>
                  <li className='modal-point'>Technique: Use a soft-bristled toothbrush. Brush in a circular motion,</li>
                  <li className='modal-point'>Time: 2 mins</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'>Floss Regularly:</span>
                
                <ul>
                <li className='modal-point'>Aim to floss once a day. Be gentle to avoid injuring your gums.</li>
                </ul>
              </li>

              <li>
              <span className='modal-subhead'>Use Mouthwash:</span>
                
                <ul>
                <li className='modal-point'>Rinse with an antimicrobial or fluoride mouthwash to help reduce plaque.</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'>Maintain a Balanced Diet:</span>
                
                <ul>
                <li className='modal-point'>Limit sugary and acidic foods, as they can contribute to tooth decay.</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'>Stay Hydrated:</span>
                
                <ul>
                <li className='modal-point'>Drinking water, helps rinse away food particles, bacteria, and acids that can contribute to tooth decay.</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'>Chew Sugar-Free Gum:</span>
                
                <ul>
                <li className='modal-point'>Chewing sugar-free gum can stimulate saliva production, which helps neutralize acids and strengthen tooth enamel.</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'>Replace Your Toothbrush:</span>
                
                <ul>
                <li className='modal-point'>
                  {/* Every three to four months, or sooner if the bristles are frayed. */}
                  Every 2.5 to 3 months, or sooner if the bristles are frayed.
                  </li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'>Regular Dental Check-ups:</span>
                
                <ul>
                <li className='modal-point'>
                  {/* Schedule regular dental check-ups and cleanings. */}
                  Schedule regular dental checkups and cleaning every 6 months.
                  </li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'>Quit Smoking:</span>
                
                <ul>
                <li className='modal-point'>
                  {/* Smoking can contribute to gum disease, tooth decay, and other oral health issues. */}
                  Refrain from using Tobacco and Tobacco-containing products .
                  </li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'>Protect Your Teeth:</span>
                
                <ul>
                <li className='modal-point'>Wear a mouthguard if you play contact sports to prevent dental injuries.</li>
                </ul>
              </li>

              <li>
                <span className='modal-subhead'>Address Dental Issues Promptly:</span>
                
                <ul>
                <li className='modal-point'>If you experience dental pain, bleeding gums, or any other oral health concerns, consult your dentist promptly.</li>
                </ul>
              </li>

            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalModal;