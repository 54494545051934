import React,{useEffect} from "react";
import { Link } from "react-router-dom";

const Header = () => {

    function handleAbout (){
        const el = document.getElementById('about');
        console.log(el);
        let timeout = setTimeout(() =>{
            el?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            })
        },200)
    }

    function handleContact (){
        const el = document.getElementById('contact');
        console.log(el);
        let timeout = setTimeout(() =>{
            el?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            })
        },200)
    }

    return(
        <div className="header container-fluid">
            <div className="col-3">
                <Link className="header-img-div" to="/">
                    <img
                        src='/headLogo.png'
                        alt='logo'
                        className="header-img"
                    />
                </Link>
            </div>
            <div className="col-4"></div>
            <div className="header-anchor">
                <Link to="/">Home</Link>
                <Link to="/#about"  onClick={() => handleAbout()}>About</Link>
                <Link to="/#contact" onClick={() => handleContact()}>Contact</Link>
            </div>
        </div>

    );
}

export default Header