import React,{useState,useEffect} from 'react';

const FAQ = () => {

    const [first,setFirst] = useState(false);
    const [second,setsecond] = useState(false);
    const [third,setthird] = useState(false);
    const [fourth,setfourth] = useState(false);
    const [fifth,setfifth] = useState(false);
    const [sixth,setsixth] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    useEffect(() => {
       const handleResize = () => {
         setIsMobile(window.innerWidth <= 767);
       };
   
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClick = (id) =>{
        if(id == 1){
            if(first == false){
                setFirst(true);
            }
            else{
                setFirst(false);
            }
        }
        else if(id ==2){
            if(second == false){
                setsecond(true);
            }
            else{
                setsecond(false);
            } 
        }
        else if(id ==3){
            if(third == false){
                setthird(true);
            }
            else{
                setthird(false);
            }
        }
        else if(id ==4){
            if(fourth == false){
                setfourth(true);
            }
            else{
                setfourth(false);
            }
        }
        else if(id ==5){
            if(fifth == false){
                setfifth(true);
            }
            else{
                setfifth(false);
            }
        }
        else if(id ==6){
            if(sixth == false){
                setsixth(true);
            }
            else{
                setsixth(false);
            }
        }
    }

  return (
    <>
    <h2 className='faq-mainhead'>FREQUENTLY ASKED QUESTIONS</h2>
    <div className='faq'>
        <div className='col-6' style={{width:"100%",paddingRight:"2rem"}}>
            <div>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <div className='col-10 faq-head'>
                        <span>Why is reducing sugar Important?</span>
                    </div>
                    <div className='col-2 button-div'>
                        <button onClick={() => handleClick(1)}>
                            <img
                                src={first ? '/close.png': '/plus.png'}
                                alt='img'
                            />
                        </button>
                    </div>
                    
                </div>
                <div>
                    {first && 
                        <span className='faq-para'>
                            Minimising sugar is vital for oral health as it fuels bacteria, causing acid production, tooth damage, and gum issues.
                        </span>
                    }
                </div>
                <div>
                    <img
                        src='/line.png'
                        alt='line'
                        style={{width:"100%"}}
                    />
                </div>
            </div>

            <div>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <div className='col-10 faq-head'>
                        <span>How can you achieve a sugar-less diet?</span>
                    </div>
                    <div className='col-2 button-div'>
                        <button onClick={() => handleClick(2)}>
                            <img
                                src={first ? '/close.png': '/plus.png'}
                                alt='img'
                            />
                        </button>
                    </div>
                    
                </div>
                <div>
                    {second && 
                        <span className='faq-para'>
                            1. Read Food Labels <br/>
                            2. Choose Whole Foods. <br/>
                            3. Limit Sugary Beverages <br/>
                            4. Reduce Added Sugar in Coffee and Tea <br/>
                            5. Choose Low-Sugar or Unsweetened Alternatives <br/>
                            6. Snack on Whole Foods <br/>
                            7. Bake and Cook at Home <br/>
                            8. Be Mindful of Sauces and Dressings <br/>
                            9. Limit Desserts and Sweets <br/>
                            10. Educate yourself and Plan <br/>
                            11. Balanced Meals <br/>
                        </span>
                    }
                </div>
                <div>
                    <img
                        src='/line.png'
                        alt='line'
                        style={{width:"100%"}}
                    />
                </div>
            </div>

            <div>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <div className='col-10 faq-head'>
                        <span>Which Type of toothpaste to be used?</span>
                    </div>
                    <div className='col-2 button-div'>
                        <button onClick={() => handleClick(3)}>
                            <img
                                src={first ? '/close.png': '/plus.png'}
                                alt='img'
                            />
                        </button>
                    </div>
                    
                </div>
                <div>
                    {third && 
                        <span className='faq-para'>
                            {/* Below 4 years – fluoridated Toothpaste and Above 4 years- Non fluoridated Toothpaste */}
                            Fluoridated toothpaste
                        </span>
                    }
                </div>
                <div>
                    <img
                        src='/line.png'
                        alt='line'
                        style={{width:"100%"}}
                    />
                </div>
            </div>

            <div>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <div className='col-10 faq-head'>
                        <span>How often should I visit the dentist?</span>
                    </div>
                    <div className='col-2 button-div'>
                        <button onClick={() => handleClick(4)}>
                            <img
                                src={first ? '/close.png': '/plus.png'}
                                alt='img'
                            />
                        </button>
                    </div>
                    
                </div>
                <div>
                    {fourth && 
                        <span className='faq-para'>
                            Every 6 months
                        </span>
                    }
                </div>
                <div>
                    <img
                        src='/line.png'
                        alt='line'
                        style={{width:"100%"}}
                    />
                </div>
            </div>

            <div>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <div className='col-10 faq-head'>
                        <span>What are the signs of gum disease?</span>
                    </div>
                    <div className='col-2 button-div'>
                        <button onClick={() => handleClick(5)}>
                            <img
                                src={first ? '/close.png': '/plus.png'}
                                alt='img'
                            />
                        </button>
                    </div>
                    
                </div>
                <div>
                    {fifth && 
                        <span className='faq-para'>
                            1. Bleeding Gums <br/>
                            2. Swollen or Tender Gums <br/>
                            3. Receding Gums <br/>
                            4. Persistent Bad Breath <br/>
                            5. Painful Chewing <br/>
                            6. Loose or Shifting Teeth <br/> 
                        </span>
                    }
                </div>
                <div>
                    <img
                        src='/line.png'
                        alt='line'
                        style={{width:"100%"}}
                    />
                </div>
            </div>

            <div>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <div className='col-10 faq-head'>
                        <span>How to manage tooth sensitivity?</span>
                    </div>
                    <div className='col-2 button-div'>
                        <button onClick={() => handleClick(6)}>
                            <img
                                src={first ? '/close.png': '/plus.png'}
                                alt='img'
                            />
                        </button>
                    </div>
                    
                </div>
                <div>
                    {sixth && 
                        <span className='faq-para'>
                            1. Use Pit & Fissure Sealants <br/> 
                            2. Soft-Bristled Toothbrush <br/> 
                            3. Gentle Brushing Technique <br/> 
                            4. Avoid Acidic Foods and Drinks <br/> 
                            5. Limit Whitening Products <br/> 
                            6. Address Grinding or Clenching <br/> 
                            7. Regular Dental Check-ups <br/> 
                            8. Dietary Changes <br/> 
                        </span>
                    }
                </div>
                <div>
                    <img
                        src='/line.png'
                        alt='line'
                        style={{width:"100%"}}
                    />
                </div>
            </div>
        </div>
        
        <div className='col-12 col-lg-6 dentist-div'>
            <img
                src={isMobile? '/dentist_mb.png' :'/dentist.png'}
                alt='dent'
                className='dentist-img'
            />
        </div>
    </div>
    </>
  );
};

export default FAQ;