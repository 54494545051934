import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from './Layout';
import Home from './pages/Home';
import Form from './pages/Form';
import './styles/sass/style.scss';
import Admin from "./pages/Admin";

const App = () => {
 return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/form" element={<Form />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </Layout>
    </Router>
 );
}

export default App;