import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PhotoSlider = ({ photos }) => {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   arrows: false,
  //   slidesToShow:  4, // Assuming you want to show one slide at a time
  //   slidesToScroll:  1, // Assuming you want to scroll one slide at a time
  //   centerPadding: '0px',
  //   adaptiveHeight: true,
  //   autoplay: true, // Enable auto-scrolling
  //   autoplaySpeed: 2000, // Auto-scroll interval (3000ms = 3 seconds)
  //   beforeChange: (oldIndex, newIndex) => {
  //     // Define handleSlideChange function or remove this if not needed
  //     // handleSlideChange();
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 768, // Applies to devices less than 768px wide
  //       settings: {
  //         slidesToShow: 1, // Show one slide at a time on small screens
  //         slidesToScroll: 1,
  //         infinite: true,
  //         centerMode: true,
  //         centerPadding: '0px',
  //       }
  //     },
  //   ],
  // };
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          autoplay: true,
          initialSlide: 1
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          initialSlide: 1
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          initialSlide: 1
        },
      },
    ],
  };
  

  return (
    <Slider {...settings}>
      {photos.map((photo, index) => (
        <div key={index}>
          <img src={photo.url} alt={photo.title} />
        </div>
      ))}
    </Slider>
  );
};

export default PhotoSlider;
